import ENV_VARIABLES from "../config/enviromentConfig";

const axios = require("axios");

export let url = ENV_VARIABLES.SSE_URL;

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  maxContentLength: Infinity,
  maxBodyLength: Infinity
};

const default_config = {
  baseURL: url,

  ...headers
};

export const publicServer = axios.create({
  ...default_config
});
