let JWT = "";
let USER = "";
let ID = "";

const TOKEN_STORATE = "custodia_token";

export default {
  getJWT: async () => {
    if (!JWT) {
      const token = localStorage.getItem(TOKEN_STORATE);
      JWT = token;
    }

    return await JWT;
  },
  setJWT: async jwt => {
    localStorage.setItem(TOKEN_STORATE, jwt);
    JWT = await jwt;
  },
  getUser: async () => {
    return await USER;
  },
  setUser: async userType => {
    USER = userType;
  },
  getId: async () => {
    return await ID;
  },
  setId: async id => {
    ID = id;
  }
};
